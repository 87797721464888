/* You can add global styles to this file, and also import other style files */



:root {
  --primary-color: #0F2FFF; //#2945ff
  --secondary-color: #a5d6f7;
  --dark-primary-color: #0047AB;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "poppins" !important;
  font-style: normal;
  font-weight: normal;
  background-color: var(--secondary-color);
}

.brandLink {
  color: var(--primary-color);
  cursor: pointer;
}

.contentP {
  font-size: 17px;
  line-height: 27px;
}

.container {
  max-width: 1250px;
}

.button-nofill {
  border-radius: 5px;
  border: none;
  background: #fff;
  padding: 10px 25px;
  color: var(--primary-color);
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}

.button-fill {
  border-radius: 15px;
  border: none;
  background: var(--dark-primary-color);
  padding: 20px 35px;
  color: #fff;
  cursor: pointer;
  // font-weight: bold;
  font-size: 23px;
}

.button-fill_1 {
  border-radius: 15px;
  border: none;
  background: var(--dark-primary-color);
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  // font-weight: bold;
  font-size: 20px;
}

.space-1 {
  height: 1px;
  width: 100%;
}

.space-2 {
  height: 2px;
  width: 100%;
}

.space-3 {
  height: 3px;
  width: 100%;
}

.space-4 {
  height: 4px;
  width: 100%;
}

.space-5 {
  height: 5px;
  width: 100%;
}

.space-10 {
  height: 10px;
  width: 100%;
}

.space-15 {
  height: 15px;
  width: 100%;
}

.space-20 {
  height: 20px;
  width: 100%;
}

.space-25 {
  height: 25px;
  width: 100%;
}

.space-30 {
  height: 30px;
  width: 100%;
}

.space-35 {
  height: 35px;
  width: 100%;
}

.space-40 {
  height: 40px;
  width: 100%;
}

.space-45 {
  height: 45px;
  width: 100%;
}

.space-50 {
  height: 50px;
  width: 100%;
}

.space-55 {
  height: 55px;
  width: 100%;
}

.space-60 {
  height: 60px;
  width: 100%;
}

.space-65 {
  height: 65px;
  width: 100%;
}

.space-70 {
  height: 70px;
  width: 100%;
}

.space-75 {
  height: 75px;
  width: 100%;
}

.space-80 {
  height: 80px;
  width: 100%;
}

.space-85 {
  height: 85px;
  width: 100%;
}

.space-90 {
  height: 90px;
  width: 100%;
}

.space-95 {
  height: 95px;
  width: 100%;
}

.space-100 {
  height: 100px;
  width: 100%;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #3a3a3a;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar,
.mat-slide-toggle-bar {
  background-color: #e3e3e3;
}

.mat-slide-toggle-thumb {
  background-color: #000000;
}

.mat-dialog-container {
  border-radius: 20px !important;
}

.startdateContainer .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: none;
  padding: 0px;
}

.startdateContainer .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}

.startdateContainer .mat-form-field-infix {
  padding: 0px;
  border-top: none;
  width: 120px;
}

.startdateContainer .mat-form-field-wrapper {
  padding-bottom: 0px;
}

.startdateContainer .mat-form-field-appearance-fill .startdateContainer .mat-form-field-underline::before,
.startdateContainer .mat-form-field-underline {
  display: none;
}

.startdateContainer .mat-form-field-flex {
  align-items: start !important;
}

.startdateContainer input.mat-input-element {
  margin-top: 2px;
  margin-left: 10px;
  color: #000;
  font-weight: bold;
}

.startdateContainer .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  align-items: start;
  color: var(--dark-primary-color);
}

img.ngx-loading-logo.center-center {
  background: #fff;
  height: 110px !important;
  width: 300px !important;
  padding: 18px 15px;
  border-radius: 10px;
}

.ngx-foreground-spinner center-center {
  top: calc((50% + 56px) - 12px);
}

.dobContainer button.mat-focus-indicator.mat-icon-button.mat-button-base {
  background: #fff;
  color: var(--dark-primary-color);
  margin-left: 10px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primary-color);
}

.loadingScreen {
  position: absolute;
  z-index: 9999;
  top: 200px;
  left: 21%;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loadingText {
  color: var(--dark-primary-color);
  font-weight: bold;
  margin-top: 15px;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

p {

  font-size: 20px;
  line-height: 30px;
}

.mat-mdc-dialog-container#newsPartnerPopup {
  background: var(--secondary-color);
}

.mat-mdc-dialog-container#kickOutPopup {
  background: url("../src/assets/images/nsurehub/saveMoneybg.jpg");
  width: 600px;
  padding: 0px !important;
}

.mat-mdc-dialog-container#kickOutPopup:before {
  background: rgba(0, 0, 0, 0.5);
  content: "";
  position: absolute;
  width: 600px;
  height: 40vh;
  border-radius: 20px;
  z-index: 9;
}

button:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

.owl-carousel .owl-item {
  transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-item .card {
  padding: 30px;
  position: relative;
}

.owl-carousel .owl-stage-outer {
  overflow-y: auto !important;
  padding-bottom: 40px;
}

.owl-carousel .owl-item img {
  height: 200px;
  object-fit: cover;
  border-radius: 6px;
}

.owl-carousel .owl-item .card .name {
  position: absolute;
  bottom: -20px;
  left: 33%;
  color: #101c81;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: aquamarine;
  padding: 0.3rem 0.4rem;
  border-radius: 5px;
  box-shadow: 2px 3px 15px #3c405a;
}

.owl-carousel .owl-item .testimonialItem {
  // opacity: 0.2;
  transform: scale3d(0.8, 0.8, 0.8);
  transition: all 0.3s ease-in-out;
}

.owl-carousel .owl-item.active.center .testimonialItem {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.owl-theme .owl-dots .owl-dot span {
  height: 20px;
  background: #2a6ba3 !important;
  border-radius: 2px !important;
  opacity: 0.8;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  height: 13px;
  width: 13px;
  opacity: 1;
  transform: translateY(2px);
  background: #83b8e7 !important;
}

@media(min-width: 480.6px) and (max-width: 575.5px) {
  .owl-carousel .owl-item .card .name {
    left: 24%;
  }
}

@media(max-width: 360px) {
  .owl-carousel .owl-item .card .name {
    left: 30%;
  }
}

/* Chekbox CSS */
.chkcontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--dark-primary-color);
}

/* Hide the browser's default checkbox */
.chkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  border: 6px solid var(--dark-primary-color);
}

/* On mouse-over, add a grey background color */
.chkcontainer:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.chkcontainer input:checked~.checkmark {
  background-color: var(--dark-primary-color);
  content: "";
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chkcontainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chkcontainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
}

@media only screen and (max-width: 420px) {
  p {
    font-size: 18px !important;
    line-height: 30px !important;
  }

  .chkcontainer {
    font-size: 18px;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 1.5rem !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface:has(.kickout_bg) {
  padding: 0 !important;
}

.mat-mdc-radio-button .mdc-form-field{
  align-items: flex-start !important;
}